<template>
  <div class='slot-machine'>
    <div class='group'
      :class='opacityBasedOnHasSpunBefore'>
      <div class='reel'><div class='reel-holder'><p>0</p><p>1</p><p>2</p><p>3</p><p>4</p><p>5</p><p>6</p><p>7</p><p>8</p><p>9</p></div><div class='reel-holder'><p>0</p><p>1</p><p>2</p><p>3</p><p>4</p><p>5</p><p>6</p><p>7</p><p>8</p><p>9</p></div><div class='reel-door' :class='unspunItem'>?</div></div>
      <div class='reel'><div class='reel-holder'><p>0</p><p>1</p><p>2</p><p>3</p><p>4</p><p>5</p><p>6</p><p>7</p><p>8</p><p>9</p></div><div class='reel-holder'><p>0</p><p>1</p><p>2</p><p>3</p><p>4</p><p>5</p><p>6</p><p>7</p><p>8</p><p>9</p></div><div class='reel-door' :class='unspunItem'>?</div></div>
      <div class='reel'><div class='reel-holder'><p>0</p><p>1</p><p>2</p><p>3</p><p>4</p><p>5</p><p>6</p><p>7</p><p>8</p><p>9</p></div><div class='reel-holder'><p>0</p><p>1</p><p>2</p><p>3</p><p>4</p><p>5</p><p>6</p><p>7</p><p>8</p><p>9</p></div><div class='reel-door' :class='unspunItem'>?</div></div>
      <div class='reel'><div class='reel-holder'><p>0</p><p>1</p><p>2</p><p>3</p><p>4</p><p>5</p><p>6</p><p>7</p><p>8</p><p>9</p></div><div class='reel-holder'><p>0</p><p>1</p><p>2</p><p>3</p><p>4</p><p>5</p><p>6</p><p>7</p><p>8</p><p>9</p></div><div class='reel-door' :class='unspunItem'>?</div></div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'LuckyDrawSpinner',
  components: {
  },
  props: {
    targetNumber: {
      type: String,
      default: '0000',
    },
  },
  data () {
    return {
      runIndex: 0,
      tMax: 4000, // animation time, ms
      height: 1000, // this is the height of one number + including margin (100) multiplied by the number of numbers (10)
      speeds: [],
      numberOutputOffset: [],
      start: null,
    }
  },
  watch: {
    targetNumber: {
      handler: function (newVal) {
        console.log('newVal target nubmer is: ', newVal)
        this.runNewNumber()
      }
    }
  },
  computed: {
    sTarget() {
      return this.targetNumber
    },
    reelsRef() {
      return this.$el.querySelectorAll('.reel')
    },
    opacityBasedOnHasSpunBefore() {
      return this.targetNumber === '0000' ? 'opacity-80' : 'opacity-100'
    },
    unspunItem() {
      return this.targetNumber === '0000' ? 'opacity-100' : 'opacity-0'
    },
  },
  methods: {
    runNewNumber () {
      // $('.reel-door').fadeOut(100);
      for (var i = 0, len = this.sTarget.length; i < len; i += 1) {
          var intOffset = (parseInt(+this.sTarget.charAt(i) | 0)) * this.height / 10 - ((this.height / 10) * 2) + 100
          this.numberOutputOffset[i] = intOffset >= 0 ? intOffset : (this.height - (this.height / 10))
      }
      for (var j = 0; j < 4; ++j) {
          this.speeds[j] = Math.random() + .7
      }
      this.animate()
    },
    animate (now) {
      if (!this.start) this.start = now
      var t = now - this.start || 0

      for (var i = 0; i < 4; ++i) {
        this.reelsRef[i].scrollTop = 
        (this.speeds[i] / this.tMax / 2 * (this.tMax - t) * (this.tMax - t) + this.numberOutputOffset[i]) 
        % this.height | 0
      }
      if (t < this.tMax) {
        requestAnimationFrame(this.animate)
      } else {
        this.start = undefined
        this.$emit('done-spinning')
      }
    }
  },
}
</script>

<style lang='scss' scoped>

.button {
  display: block;
  width: 100%;
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #da274d;
  &:hover {
    cursor: pointer;
    background-color: darken(#da274d, 5%);
  }
}

.slot-machine {
  width: 480px;
}

.group {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  border-radius: 32px;
}

.reel {
  position: relative;
  float: left;
  width: 100px;
  height: 120px;
  padding: 0 10px;
  overflow: hidden;
  text-align: center;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 .2rem .7rem rgba(0, 0, 0, .3) inset, 0 0 .1rem rgba(0, 0, 0, .2) inset;

}
.reel-holder {
  position: relative;
  text-align: center;
  top: -72px;
}

.reel-door {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 40px;
  font-weight: bold;
  background: #FEFEFE;
 }

.reel p {
  line-height: 23px;
  margin-top: 20px;
  margin-bottom: 0;
  height: 80px;
  font-size: 64px;
  font-weight: bold;
}
</style>
