<template>
  <div 
    class='flex flex-row justify-center items-center gap-x-24'
    style='height:calc(100vh);background-color:#1F3336;background-image:url("https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/gbcc2024/luckydraw_bg.jpg")'>
    <div  
      v-if='false' 
      class='flex-grow-0 flex-shrink-0 w-1/2 flex flex-col justify-start py-8 overflow-y-auto'
      style='height: calc(100vh);'>
      <div>
        <div v-for='(selectedNumber, index) in selectedNumbers'
          :key='`selected-numbers-${selectedNumber}`'
          class='selected-number bg-gray-100 rounded-md my-2 flex flex-row justify-start items-center gap-x-4 border max-w-sm w-full mx-auto'
          :class='showLastSelectedNumber(index)'>
          <div class='p-2 text-center w-10 flex-grow-0 flex-shrink-0'>{{index + 1}}</div><div class='px-2 py-4 text-center flex-grow bg-white font-semibold rounded-r-md text-3xl'>{{ selectedNumber }}</div>
        </div>
      </div>
    </div>
    <div 
      class='flex-grow-0 flex-shrink-0 max-w-6xl mx-auto flex flex-col justify-center items-center gap-y-4 py-8 overflow-y-auto'
      style='height: calc(100vh);'>
      <div class='text-center text-yellow-200 handwriting py-10 px-8' style='font-size:9rem;background-color:#1F3336;'>☆ Lucky Draw ☆</div>
      <lucky-draw-spinner 
        :target-number='spinnerTargetNumberString'
        @done-spinning='doneSpinning'
        style='background-color:#1F3336;' />
      <button 
        class='uppercase rounded-md px-16 py-6 font-semibold text-2xl text-black mb-8 disabled:opacity-50 w-full max-w-lg mx-auto'
        style='background-color: #F3BDF5;'
        :disabled='!newNumberEnabled'
        @click='addNewNumber'>
        Spin
      </button>
      <img src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/gbcc2024/gbcc_guy_solid_2024.png' 
        class='hidden w-40 lg:w-full text-white p-24' 
        style='max-width:32rem;' />
    </div>
  </div>
</template>

<script>
import LuckyDrawSpinner from '@/components/gbcc2024/LuckyDrawSpinner.vue'

export default {
  name: 'LuckyDraw',
  components: {
    LuckyDrawSpinner,
  },
  data () {
    return {
      selectedNumbers: [],
      newNumberEnabled: true,
      spinning: false,
      maxNumber: 2000,
    }
  },
  computed: {
    spinnerTargetNumberString () {
      return this.selectedNumbers.length ? this.selectedNumbers[this.selectedNumbers.length - 1].toString() : '0000'
    },
  },
  methods: {
    getNewNumber () {
      return (Math.floor(Math.random() * 10000) % this.maxNumber).toString().padStart(4, '0')
    },
    addNewNumber () {
      this.newNumberEnabled = false
      this.spinning = true
      let newNumber = this.getNewNumber()
      while (this.selectedNumbers.includes(newNumber)) {
        newNumber = this.getNewNumber()
      }
      this.selectedNumbers.push(newNumber)
    },
    doneSpinning () {
      this.newNumberEnabled = true
      this.spinning = false
    },
    showLastSelectedNumber(index) {
      if (index < this.selectedNumbers.length - 1) {
        return 'opacity-100'
      }
      return this.spinning ? 'opacity-0' : 'opacity-100'
    },
  },
  mounted () {
    let maxFromQueryParams = this.$route.query.max
    if (maxFromQueryParams) {
      this.maxNumber = Math.min(parseInt(maxFromQueryParams), 9999)
    }
    this.$emit('update:layout', 'empty-page')
  },
  beforeDestroy () {
    this.$emit('update:layout', 'default-layout')
  },
}
</script>

<style lang='scss'>
@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');

.handwriting {
  font-family: 'Satisfy', cursive;
}
</style>