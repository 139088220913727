var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slot-machine"},[_c('div',{staticClass:"group",class:_vm.opacityBasedOnHasSpunBefore},[_c('div',{staticClass:"reel"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"reel-door",class:_vm.unspunItem},[_vm._v("?")])]),_c('div',{staticClass:"reel"},[_vm._m(2),_vm._m(3),_c('div',{staticClass:"reel-door",class:_vm.unspunItem},[_vm._v("?")])]),_c('div',{staticClass:"reel"},[_vm._m(4),_vm._m(5),_c('div',{staticClass:"reel-door",class:_vm.unspunItem},[_vm._v("?")])]),_c('div',{staticClass:"reel"},[_vm._m(6),_vm._m(7),_c('div',{staticClass:"reel-door",class:_vm.unspunItem},[_vm._v("?")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reel-holder"},[_c('p',[_vm._v("0")]),_c('p',[_vm._v("1")]),_c('p',[_vm._v("2")]),_c('p',[_vm._v("3")]),_c('p',[_vm._v("4")]),_c('p',[_vm._v("5")]),_c('p',[_vm._v("6")]),_c('p',[_vm._v("7")]),_c('p',[_vm._v("8")]),_c('p',[_vm._v("9")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reel-holder"},[_c('p',[_vm._v("0")]),_c('p',[_vm._v("1")]),_c('p',[_vm._v("2")]),_c('p',[_vm._v("3")]),_c('p',[_vm._v("4")]),_c('p',[_vm._v("5")]),_c('p',[_vm._v("6")]),_c('p',[_vm._v("7")]),_c('p',[_vm._v("8")]),_c('p',[_vm._v("9")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reel-holder"},[_c('p',[_vm._v("0")]),_c('p',[_vm._v("1")]),_c('p',[_vm._v("2")]),_c('p',[_vm._v("3")]),_c('p',[_vm._v("4")]),_c('p',[_vm._v("5")]),_c('p',[_vm._v("6")]),_c('p',[_vm._v("7")]),_c('p',[_vm._v("8")]),_c('p',[_vm._v("9")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reel-holder"},[_c('p',[_vm._v("0")]),_c('p',[_vm._v("1")]),_c('p',[_vm._v("2")]),_c('p',[_vm._v("3")]),_c('p',[_vm._v("4")]),_c('p',[_vm._v("5")]),_c('p',[_vm._v("6")]),_c('p',[_vm._v("7")]),_c('p',[_vm._v("8")]),_c('p',[_vm._v("9")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reel-holder"},[_c('p',[_vm._v("0")]),_c('p',[_vm._v("1")]),_c('p',[_vm._v("2")]),_c('p',[_vm._v("3")]),_c('p',[_vm._v("4")]),_c('p',[_vm._v("5")]),_c('p',[_vm._v("6")]),_c('p',[_vm._v("7")]),_c('p',[_vm._v("8")]),_c('p',[_vm._v("9")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reel-holder"},[_c('p',[_vm._v("0")]),_c('p',[_vm._v("1")]),_c('p',[_vm._v("2")]),_c('p',[_vm._v("3")]),_c('p',[_vm._v("4")]),_c('p',[_vm._v("5")]),_c('p',[_vm._v("6")]),_c('p',[_vm._v("7")]),_c('p',[_vm._v("8")]),_c('p',[_vm._v("9")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reel-holder"},[_c('p',[_vm._v("0")]),_c('p',[_vm._v("1")]),_c('p',[_vm._v("2")]),_c('p',[_vm._v("3")]),_c('p',[_vm._v("4")]),_c('p',[_vm._v("5")]),_c('p',[_vm._v("6")]),_c('p',[_vm._v("7")]),_c('p',[_vm._v("8")]),_c('p',[_vm._v("9")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reel-holder"},[_c('p',[_vm._v("0")]),_c('p',[_vm._v("1")]),_c('p',[_vm._v("2")]),_c('p',[_vm._v("3")]),_c('p',[_vm._v("4")]),_c('p',[_vm._v("5")]),_c('p',[_vm._v("6")]),_c('p',[_vm._v("7")]),_c('p',[_vm._v("8")]),_c('p',[_vm._v("9")])])
}]

export { render, staticRenderFns }